import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { Share2, Image, Settings } from 'react-feather';
import Spinner from '../../common/Spinner';
import AccordionHeader from '../../components/AccordionHeader';
import DeviceCard from './DeviceCard';
import FieldSettingsModal from './FieldSettingsModal';
import ShareFieldModal from './ShareFieldModal';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useToasts } from 'react-toast-notifications';
import { GET_PLACE_BY_GUID } from '../../GraphQL/Queries/Places';
import { GET_SHARED_WITH, GET_PEOPLE_BY_EMAIL } from '../../GraphQL/Queries/People';
import { UPDATE_PLACE, SHARE_FIELD, REMOVE_SHARED_FIELD } from '../../GraphQL/Mutations/Places';
import { GET_FIELD_SENSOR_PREVIEW } from '../../GraphQL/Queries/Things';
import {
    UPDATE_PLACE_LINK_CROP,
} from '../../GraphQL/Mutations/Places';
// TODO: Turn this into a proper component rather than nesting a bunch of garbage
const FieldCard = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const {
        field,
        fieldOpen,
        handleFieldOpen,
        gaugesCalled,
        gaugesLoading,
        guidArray,
        grower
    } = props;
    const [deviceOpen, setDeviceOpen] = useState(null);
    const { addToast } = useToasts();
    const [updatePlaceLinkCrop] = useMutation(UPDATE_PLACE_LINK_CROP);
    const [updatePlace] = useMutation(UPDATE_PLACE);
    const [fieldDetails, setFieldDetails] = useState(null);
    const [sharedWith, setSharedWith] = useState(null);
    const [sharedWithBackup, setSharedWithBackup] = useState(null);
    const [searchString, setSearchString] = useState(null);
    const [fieldPreview, setFieldPreview] = useState(null);;
    const [place, setPlace] = useState(null);
    const [cropYearSelected, setCropYearSelected] = useState("");
    const {
        loading: gettingPlace,
        called: getPlaceCalled,
        error: errorGettingPlace,
        data: placeData,
        refetch: refetchPlace
    } = useQuery(
        GET_PLACE_BY_GUID,
        {
            variables: {
                guid: field.guid
            },
            context: { service: 'places' }
        }
    );
    const [
        lazyPreview,
        {
            loading: gaugeLoading,
            called: gaugeCalled,
            error: gaugeError,
            data: gaugeData,
            refetch: refetchFieldSensorPreview,
        }
    ] = useLazyQuery(
        GET_FIELD_SENSOR_PREVIEW, { fetchPolicy: 'network-only' }
    );
    const {
        data: sharedData,
    } = useQuery(
        GET_SHARED_WITH,
        {
            variables: {
                guid: field.guid
            },
            context: { service: 'people' }
        }
    );

    const {
        loading: searchLoading,
        error: searchError,
        data: searchData,
        refetch: refetchSearch
    } = useQuery(
        GET_PEOPLE_BY_EMAIL,
        {
            variables: {
                email: searchString || '',
            },
            context: { service: 'people' }
        }
    );


    const FieldHeader = (props) => {
        const {
            field,
            fieldPreview,
        } = props;

        // PLT Set

        useEffect(() => {
            if (getPlaceCalled && !gettingPlace && !errorGettingPlace && !place) {
                const { getPlaceByGuid: { results } } = placeData;
                if (results.length > 0) {
                    const row = results[0];
                    setPlace(row);
                    // console.log(row);
                    lazyPreview({
                        variables: { placeGuid: row.guid },
                        context: { service: 'things' }
                    });
                }
            }
        });

        const setFixed = (value, pos) => {
            return Number(value).toFixed(pos);
        }

        const hasDeviceGauge = (device) => {
            return (device && device.svgGauge && device.svgGauge !== null);
        }

        const show24HRain = () => {
            if (fieldDetails !== null && fieldDetails.hasOwnProperty('rain24h') && fieldDetails.rain24h !== null) {
                return (
                    <li><label>24hr Rain:</label>{fieldDetails.rain24h}</li>
                );
            }

            return (null);
        }

        const showET = () => {
            if (fieldDetails !== null && fieldDetails.hasOwnProperty('et') && fieldDetails.et !== null) {
                return (
                    <li><label>ET:</label>{setFixed(fieldDetails.et, 2)}&quot;</li>
                );
            }

            return (null);
        }

        const showCrop = () => {
            if (fieldDetails !== null && fieldDetails.hasOwnProperty('crop') && fieldDetails.stage !== null) {
                return (
                    <li><label>Crop:</label>{fieldDetails.crop}</li>
                );
            }

            return (null);
        }

        const showStage = () => {
            if (fieldDetails !== null && fieldDetails.hasOwnProperty('stage') && fieldDetails.stage !== null) {
                return (
                    <li><label>Stage:</label>{fieldDetails.stage}</li>
                );
            }

            return (null);
        }

        useEffect(() => {
            if (fieldPreview !== null) {
                setFieldDetails(fieldPreview);
            }
        }, [fieldPreview, field]);

        // useEffect(() => {
        //     const currentYear = new Date().getFullYear();
        //     if (field && field.cropYear && field.cropYear.length > 0) {
        //         setCropYearSelected(field.cropYear)
        //     } else {
        //         setCropYearSelected(JSON.stringify(currentYear))
        //     }
        // }, [field])

        useEffect(() => {
            // const currentYear = new Date().getFullYear();
            if (cropYearSelected === "") {
                setCropYearSelected("2023")
            }
        })

        if (field === null) {
            return (null);
        }



        return (
            <Container fluid className="field-header">
                <Row>
                    <Col xs={{ span: 2, order: 'last' }} sm={{ span: 2, order: 'first' }}>
                        <Row>
                            <Col xs={2} style={{ marginBottom: "8px" }}>
                                <Settings size={18} onClick={() => setModalShow(true)}></Settings>
                            </Col>
                            <Col xs={2} style={{ marginBottom: "8px" }}>
                                <Share2 size={18} onClick={() => setShowShareModal(true)}></Share2>
                            </Col>
                            <Col xs={2} style={{ marginBottom: "8px" }}>
                                {field.senteraMapUrl && <a href={"http://" + field.senteraMapUrl} target="_blank" rel="noopener noreferrer">
                                    <Image color="black" size={18}></Image>
                                </a>}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 10, order: 'last' }} sm={{ span: 2, order: 'first' }} className="field-name">
                        Crop Year:&nbsp;&nbsp;<b>{cropYearSelected}</b>
                    </Col>
                    <Col xs={{ span: 10, order: 'last' }} sm={{ span: 2, order: 'first' }} className="field-name">
                        {field.fieldName}
                    </Col>
                    <Col xs={{ span: 12, order: 'first' }} sm={{ span: 10, order: 'last' }} className="field-gauges">
                        {(gaugeLoading && fieldDetails === null) &&
                            <Spinner align="left" />
                        }
                        {fieldDetails !== null && fieldDetails.devices && fieldDetails.devices.map((device, index) =>
                            hasDeviceGauge(device) ? (
                                <img
                                    src={`data:image/svg+xml;utf8,${encodeURIComponent(device.svgGauge)}`}
                                    key={index}
                                    width="42"
                                    border="0"
                                    alt={device.gaugeValue ? device.gaugeValue : "Soil Moisture Gauge SVG"}
                                    style={{ marginRight: 12 }}
                                />
                            ) : (null)
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(fieldDetails !== null) ? (
                            <ul className="field-details">
                                {showCrop() || ""}
                                {showStage() || ""}
                                {show24HRain() || ""}
                                {showET() || ""}
                            </ul>
                        ) : (null)}
                    </Col>
                </Row>
            </Container >
        )
    }

    const timeout = useCallback((ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }, [])

    const handleRefetch = useCallback(async () => {
        setFieldPreview(null);
        setFieldDetails(null);
        await timeout(1000);
        refetchFieldSensorPreview();
    }, [setFieldPreview, setFieldDetails, timeout, refetchFieldSensorPreview])


    useEffect(() => {
        if (gaugeCalled && !gaugeLoading && !gaugeError && fieldPreview === null) {
            const { getFieldSensorPreview: { results } } = gaugeData;

            if (results === null) {
                refetchFieldSensorPreview();
            } else if (results !== null && results.length > 0) {
                console.log("SETTING FIELD PREVIEW");
                setFieldPreview(results[0]);
            }
        }
    }, [gaugeLoading, gaugeCalled, gaugeError, gaugeData, refetchFieldSensorPreview, fieldPreview, setFieldPreview]);
    const [shareField] = useMutation(SHARE_FIELD);
    const [removeSharedField] = useMutation(REMOVE_SHARED_FIELD);
    const handleFieldSettings = React.useCallback(async (data) => {
        const d = JSON.parse(JSON.stringify(data));
        // console.log("DATA", d);
        const placeData = { ...d };
        const guid = placeData.guid;
        const cropData = { ...placeData.link }

        // console.log("PLACE", placeData);
        delete placeData['__typename'];

        const crop = JSON.parse(JSON.stringify(cropData));

        if (Object.keys(crop).length > 0) {
            console.log("CROP", crop);
            const vars = {
                placeGuid: placeData.guid,
                cropGuid: crop.cropGuid,
                link: {
                    plantingDate: Number(crop.plantingDate),
                    currentGrowthStage: crop.currentGrowthStage,
                    maturity: crop.maturity,
                }
            }

            if (crop.guid) {
                vars.link.guid = crop.guid;
            }

            //TODO: Save Crop Seperately
            if (crop.guid) {

                vars.link.guid = crop.guid;
                await updatePlaceLinkCrop({
                    variables: vars,
                    context: { service: 'places' }
                });

            } else {
                const response = await updatePlaceLinkCrop({
                    variables: vars,
                    context: { service: 'places' }
                });

                const { data: { updatePlaceLinkCrop: { results } } } = response;

                if (results.length > 0) {
                    const newGuid = results[0];

                    placeData.link.guid = newGuid;
                }
            }

        }
        // console.log("PLACE DATA", placeData);
        var tempPlaceData = { ...placeData };
        if (tempPlaceData.link) {
            delete tempPlaceData.link;
        }
        if (tempPlaceData.crop) {
            delete tempPlaceData.crop;
        }
        const place = {
            ...tempPlaceData,
            lat: Number(tempPlaceData.lat) || 0,
            lng: Number(tempPlaceData.lng) || 0,
            soilTexture: tempPlaceData.soilTexture || '',
            oneFtSoilTexture: tempPlaceData.oneFtSoilTexture || '',
            pivotPassAmount: Number(tempPlaceData.pivotPassAmount) || 0,
            wellCapacity: Number(tempPlaceData.wellCapacity) || 0,
            acreInches: Number(tempPlaceData.acreInches) || 0,
        }


        Object.keys(place).forEach((key) => {
            if (place[key] === null) {
                delete place[key];
            }
        });

        const response = await updatePlace(
            {
                variables: {
                    guid,
                    place,
                },
                context: {
                    service: 'places'
                },
            }
        );

        const { data: { updatePlace: { ok } } } = response;

        // var ok = true;
        if (ok && ok === true) {
            addToast('Settings were successfully updated', {
                appearance: 'success',
                autoDismiss: true,
            });

            place.link = cropData;
            handleRefetch();
            refetchPlace();
        } else {
            addToast('There was a problem saving the settings', {
                appearance: 'error',
            });
        }

    }, [updatePlace, refetchPlace, handleRefetch, addToast, updatePlaceLinkCrop]);

    const handleSetCropYear = (yearChange) => {
        setCropYearSelected(yearChange)
    }

    const handleDeviceOpen = (deviceId) => {
        const deviceName = 'device' + deviceId;

        if (deviceName !== deviceOpen) {
            setDeviceOpen(deviceName);
        } else {
            setDeviceOpen(null);
        }
    }

    const handleShareField = () => {
        const fieldGuid = field.guid;
        if (sharedWithBackup && sharedWithBackup !== sharedWith) {
            console.log("BACKUP", sharedWithBackup);
            console.log("OG", sharedWith);
            const added = sharedWith.filter(person => !sharedWithBackup.includes(person));
            const removed = sharedWithBackup.filter(person => !sharedWith.includes(person));
            setSharedWithBackup(null);
            removed.map((person) => {
                removeSharedField({
                    variables: {
                        place: fieldGuid,
                        person: person.guid
                    },
                    context: {
                        service: 'places'
                    }
                });
                return person;
            });
            added.map((person) => {
                shareField({
                    variables: {
                        place: fieldGuid,
                        person: person.guid
                    },
                    context: {
                        service: 'places'
                    }
                });
                return person;
            });
            addToast('Shared Fields Successfully Updated', {
                appearance: 'success',
                autoDismiss: true,
            });
        } else if (sharedWith && sharedWith.length > 0) {
            sharedWith.map((person) => {
                shareField({
                    variables: {
                        place: fieldGuid,
                        person: person.guid
                    },
                    context: {
                        service: 'places'
                    }
                });
                return person;
            });
            addToast('Shared Fields Successfully Updated', {
                appearance: 'success',
                autoDismiss: true,
            });
        }
    }

    const handleSearchPerson = (email) => {
        setSearchString(email);
        refetchSearch();
    }

    const handleDeletePerson = (person) => {
        if (sharedWith) {
            if (sharedWithBackup === null) {
                setSharedWithBackup(sharedWith);
            }
            const newArray = sharedWith.filter(x => x !== person);
            setSharedWith(newArray);
        }
    }

    const handleClose = () => {
        setShowShareModal(false);
        if (sharedWithBackup !== null) {
            setSharedWith(sharedWithBackup);
            setSharedWithBackup(null);
        }
    }

    useEffect(() => {
        if (sharedData && sharedData !== null && sharedWith === null) {
            const { getSharedWith: { results } } = sharedData;
            setSharedWith(results);
        }
    }, [sharedData, sharedWith]);

    useEffect(() => {
        if (!searchLoading && !searchError) {
            const { getPeopleByEmail: { results } } = searchData;
            // setSharedWith(results);
            // console.log(results);
            if (results.length > 0) {
                if (sharedWithBackup == null) {
                    setSharedWithBackup(sharedWith);
                }
                let sharedWithArray = [...sharedWith];
                sharedWithArray.push(results[0]);
                setSharedWith(sharedWithArray);
            }
        }
    }, [searchLoading, searchError, searchData, sharedWith, sharedWithBackup]);

    return (
        <>
            <AccordionHeader
                eventKey={'field-' + field.guid}
                onClick={() => handleFieldOpen(field.guid)}
                isOpen={fieldOpen}
            >
                <FieldHeader
                    gaugesCalled={gaugesCalled}
                    gaugesLoading={gaugesLoading}
                    field={field}
                    fieldPreview={fieldPreview}
                    refetchFieldSensorPreview={handleRefetch}
                />
            </AccordionHeader>
            <Accordion.Collapse eventKey={'field-' + field.guid} mountOnEnter unmountOnExit>
                <DeviceCard
                    field={field}
                    fieldPreview={fieldPreview}
                    lazyPreview={lazyPreview}
                    deviceOpen={deviceOpen}
                    handleDeviceOpen={handleDeviceOpen}
                    refetchFieldSensorPreview={handleRefetch}
                    guidArray={guidArray}
                    grower={grower}
                    cropYearSelected={cropYearSelected}
                />
            </Accordion.Collapse>
            <FieldSettingsModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                handleFieldSettings={handleFieldSettings}
                place={place}
                handleSetCropYear={handleSetCropYear}
            />
            <ShareFieldModal
                show={showShareModal}
                onHide={() => setShowShareModal(false)}
                handleClose={handleClose}
                handleShareField={handleShareField}
                handleFindPerson={handleSearchPerson}
                handleDeletePerson={handleDeletePerson}
                sharedWith={sharedWith}
                place={place}
            />
        </>
    );

}

export default FieldCard;