export const crops = [
    'Alfalfa',
    'Bean, Dry',
    'Bean, Great Northern',
    'Corn',
    'Cotton',
    'Dry Bean',
    'Fall Oats',
    'Field Corn',
    'Forage Sorghum',
    'Grain Sorghum',
    'Oats',
    'Orchardgrass',
    'Seed Corn',
    'Silage Corn',
    'Sorghum',
    'Soybeans',
    'Spring Oats',
    'Sunflower, Oilseed',
    'Sunflowers',
    'Timothy',
    'Triticale',
    'Turnip',
    'Wheat',
    'Winter Wheat'
];

export const irrigationTypes = [
    'Flood',
    'Pivot',
    'Sprinkler',
    'Subsurface Drip',
    'Walker',
];

export const soilTextures = [
    'Clay',
    'Clay Loam',
    'Loam',
    'Loamy Sand',
    'Sand',
    'Sandy Clay',
    'Sandy Clay Loam',
    'Sandy Loam',
    'Silt',
    'Silt Loam',
    'Silty Clay',
    'Silty Clay Loam',
];

export const growthStages = [
    '1 Leaf',
    '2 Leaf',
    '3 Leaf',
    '4 Leaf',
    '5 Leaf',
    '6 Leaf',
    '7 Leaf',
    '8 Leaf',
    '9 Leaf',
    '10 Leaf',
    '11 Leaf',
    '12 Leaf',
    '13 Leaf',
    '14 Leaf',
    '15 Leaf',
    '16 Leaf',
    '17 Leaf',
    '18 Leaf',
    '19 Leaf',
    '20 Leaf',
    'Blister',
    'Dent',
    'Dough',
    'Emergence',
    'Mature',
    'Milk',
    'Planting',
    'Silk',
    'Tassel',
];

export const cropYears = [
    '2021',
    '2022',
    '2023'
];