import React, { useEffect, useState } from 'react';
import PageWrapper from '../../components/PageWrapper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation,  } from '@apollo/client';
import ContentHeader from '../../components/ContentHeader';
import Firmware from './Types/Firmware'
import Gateway from './Types/Gateway'
import Status from './Types/Status'
import Moisture from './Types/Moisture'
import Rain from './Types/Rain'
import Gps from './Types/Gps'
import Pivot from './Types/Pivot'
import Sensor from './Types/Sensor';
import { Form, Button, Row, Col } from 'react-bootstrap';
import LastMessage from './LastMessage';
import { CALL_SONALERT } from '../../GraphQL/Mutations/Things';

const messageTypes = ['firmware', 'rain', 'pivot', 'moisture', 'gps', 'status'];

let hexTimeout = undefined;

const Dashboard = () => {
    const now = new Date();
    const lastWeek = new Date(now.valueOf() - (7 * 24 * 60 * 60 * 1000));

    const [form, setForm] = useState(null);
    const [messageType, setMessageType] = useState(null);
    const [startDate, setStartDate] = useState(lastWeek);
    const [endDate, setEndDate] = useState(now);
    const [deveui, setDeveui] = useState('');
    const [device, setDevice] = useState('');
    const [checkDevice, setCheckDevice] = useState('');
    const [hexDevice, setHexDevice] = useState('');
    const [hex, setHex] = useState('');
    const [deviceDeveui, setDeviceDeveui] = useState('');
    const [callSonalert] = useMutation(CALL_SONALERT)
    // 1049169

    const handleDecimalDeviceInput = async (event) => {
        if (event.target.value) {
            setCheckDevice(event.target.value);
        } else {
            setCheckDevice("");
        }
    }
    const handleSetDeviceDeveui = (event) => {
        setDeveui(parseInt(deviceDeveui))
    }

    const handleSetType = (event) => {
        setMessageType(event.target.value);
    }
    const handleSetDeveui = (event) => {
        setDeviceDeveui(parseInt(event.target.value));
    }
    const handleSetDeviceToCheck = (event) => {
        setDevice(parseInt(checkDevice))
    }
    const handleSonalert = (event) => {
        var deveui;
        if (deviceDeveui){
            deveui = deviceDeveui;
        } else if (hex) {
            deveui = parseInt(hex, 16);
        }
        // const deveui = parseInt(event.target.value)
        callSonalert({
            variables: { deveui: deveui },
            context: { service: 'things' }
        });
    }

    const hexToDeveui = (val) => {
        // yourNumber = parseInt("0300104D", 16)
        return parseInt(val, 16);
    }
    const handleHexIdChange = (event) => {
        const value = event.target.value;
        setHexDevice(value);
        window.clearTimeout(hexTimeout);
        window.setTimeout(() => {
            setCheckDevice(hexToDeveui(value))
        }, 300);
    }

    const handleHexChange = (event) => {
        const value = event.target.value;
        setHex(value);
        window.clearTimeout(hexTimeout);
        window.setTimeout(() => {
            setDeviceDeveui(hexToDeveui(value))
        }, 300);
    }

    const handleCheckDeviceReset = () => {
        document.getElementById("check-Device-Form").reset();
        setDevice(null);
        setHexDevice(null);
        setCheckDevice(null)
    }

    const handleDeviceDataReset = () => {
        document.getElementById("device-Data-Form").reset();
        setDeviceDeveui(null);
        setHex(null);
        setDeveui(null)
    }

    useEffect(() => {
        if (form === null) {
            setForm({ messageType: '', deveui: '' });
        }
    }, [form]);

    useEffect(() => {
        if (deveui === '' || isNaN(deveui)) {
            setDeveui(null);
        }
    }, [deveui])

    useEffect(() => {
        if (device === '' || isNaN(device)) {
            setDevice(null);
        }
    }, [device])

    useEffect(() => {
        if (hexDevice === '') {
            setHexDevice(null);
        }
    }, [hexDevice])

    useEffect(() => {
        if (hex === '') {
            setHex(null);
        }
    }, [hex])


    return (
        <PageWrapper>
            <div className="content-wrapper">
                <ContentHeader title="Moisture Device Checker" />
                <Col xs={12} md={6} align="center">
                    <form id="check-Device-Form">
                        <Row>
                            <Form.Control
                                type="text"
                                as="input"
                                name="device"
                                size="sm"
                                placeholder="Enter a deveui"
                                onChange={handleDecimalDeviceInput}
                            />
                            <Form.Control
                                type="text"
                                as="input"
                                name="hexDevice"
                                size="sm"
                                placeholder="or a Hex Id"
                                onChange={handleHexIdChange}
                            />
                            <Button variant="primary" size="sm" onClick={handleSetDeviceToCheck}>Check Device</Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button variant="primary" size="sm" onClick={handleCheckDeviceReset}>Reset Device</Button>
                        </Row>
                    </form>
                </Col>
                <Col xs={12}>
                    {device && !isNaN(device) && <Sensor device={device} />}
                </Col>
                <ContentHeader title="Device Dashboard" />
                <Col xs={12} md={6} align="center">
                <form id="device-Data-Form">
                    {/* {form && */}
                    <Row>
                        <Form.Control
                            type="text"
                            as="input"
                            name="deveui"
                            size="sm"
                            placeholder="Enter a deveui"
                            onChange={handleSetDeveui}
                        />
                        <Form.Control
                            type="text"
                            as="input"
                            name="hex"
                            size="sm"
                            placeholder="or a Hex Id"
                            onChange={handleHexChange}
                        />
                        <Button variant="primary" size="sm" onClick={(e) => handleSonalert(e)}>Son-alert</Button>
                    </Row>
                    {/* } */}
                    {/* {form && */}
                    <Row>
                        <Form.Control
                            as="select"
                            size="sm"
                            name="messageType"
                            value={messageType || ''}
                            onChange={handleSetType}
                        >

                            <option value="">Select a Message Type</option>
                            {messageTypes.map((m, index) =>
                                <option value={m} key={index}>{m}</option>
                            )}
                        </Form.Control>
                    </Row>
                    {/* } */}

                    <Row>
                        <Form.Label>Start Date: &nbsp;</Form.Label>
                        <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                        <div style={{ width: "2.5vw" }}></div>
                        <Form.Label>End Date: &nbsp;</Form.Label>
                        <DatePicker selected={endDate} onChange={date => setEndDate(date)} />
                    </Row>
                    <Row>    
                        <Button variant="primary" size="sm" onClick={handleSetDeviceDeveui}>Load Data</Button>
                            &nbsp;&nbsp;&nbsp;
                        <Button variant="primary" size="sm" onClick={handleDeviceDataReset}>Reset Device</Button>
                    </Row>
                    </form>
                </Col>
                <Col xs={12}>
                    {deveui && !isNaN(deveui) && <div>
                        {messageType === 'firmware' && <Firmware deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                        {messageType === 'status' && <Status deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                        {messageType === 'gateway' && <Gateway deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                        {messageType === 'moisture' && <Moisture deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                        {messageType === 'rain' && <Rain deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                        {messageType === 'pivot' && <Pivot deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                        {messageType === 'gps' && <Gps deveui={deveui} start={startDate.valueOf()} end={endDate.valueOf()} />}
                    </div>
                    }
                </Col>
                {deveui && !isNaN(deveui) && <LastMessage deveui={deveui}></LastMessage>}
            </div>
        </PageWrapper >
    );
}


export default Dashboard;
