import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { GET_LAST_MESSAGE, GET_GPS_DATA, GET_STATUS_DATA } from '../../GraphQL/Queries/Things';
import { JsonFormatter } from 'react-json-formatter'

const LastMessage = (props) => {
  const {
    deveui,
  } = props;

  const [lastMessage, setLastMessage] = useState(null);
  const [lastGPS, setLastGPS] = useState(null);
  const [lastStatus, setLastStatus] = useState(null);
  const [goFetch, setFetch] = useState(false);
  const [getGPS, setGetGPS] = useState(false);
  const [getStatus, setGetStatus] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { loading: gettingLastMessage, error: errorGettingLastMessage, data: thisMessage, refetch: refetchMessage } = useQuery(
    GET_LAST_MESSAGE, { context: { service: 'things' }, variables: { key: deveui.toString() } , fetchPolicy: "network-only" });
  const { loading: gettingGPS, error: errorGettingGPS, data: gpsData, refetch: refetchGPS } = useQuery(
    GET_GPS_DATA, { context: { service: 'things' }, variables: { deveui: deveui, start: startDate, end: endDate } });
  const { loading: gettingStatus, error: errorGettingStatus, data: statusData, refetch: refetchStatus } = useQuery(
    GET_STATUS_DATA, { context: { service: 'things' }, variables: { deveui: deveui, start: startDate, end: endDate } });

  useEffect(() => {
    if (goFetch && !gettingLastMessage && !errorGettingLastMessage) {
      const { getLastMessage: { results } } = thisMessage;

      if (results && results.length) {
        const raw = results[0];
        const js = JSON.parse(raw);
        const justMessage = js[1][1];
        setLastMessage(justMessage);
      }
    }
  }, [gettingLastMessage, errorGettingLastMessage, thisMessage, goFetch]);

  useEffect(() => {
    if (getGPS && !gettingGPS && !errorGettingGPS) {
      const { getGpsData: { results } } = gpsData;

      if (results && results.length) {
        const latest = results[0];
        const lastGPSDate = new Date(latest.epoch);
        const now = new Date();
        const since = Math.floor((now - lastGPSDate) / (1000 * 60 * 60));

        var gpsMessage = since + ' hrs ago';
        setLastGPS(gpsMessage);
      }
    }
  }, [gettingGPS, errorGettingGPS, getGPS, gpsData, goFetch]);

  useEffect(() => {
    if (getStatus && !gettingStatus && !errorGettingStatus) {
      const { getStatusData: { results } } = statusData;

      if (results && results.length) {
        const latest = results[0];
        const lastStatusDate = new Date(latest.epoch);
        const now = new Date();
        const since = Math.floor((now - lastStatusDate) / (1000 * 60 * 60));

        var statusMessage = since + ' hrs ago';
        setLastStatus(statusMessage);
      }
    }
  }, [gettingStatus, errorGettingStatus, getStatus, statusData, goFetch]);

  const handleFetchLastMessage = () => {
    setFetch(true);
    setStartDate(new Date(new Date().getFullYear(), 0, 1).valueOf());
    setEndDate(new Date().valueOf());
    setGetGPS(true);
    setGetStatus(true);
    refetchMessage();
    refetchGPS();
    refetchStatus();
  }
  const handleHideMessage = () => {
    setFetch(false);
    setGetGPS(false);
    setGetStatus(false);
  }

  const getLastMessage = (deveui) => {
    if (goFetch) {
      return (
        <div style={{ "textAlign": "left", "marginLeft": "0px", "overflow": "auto", "height": "25vh" }}>
          <label>Last GPS:</label> {lastGPS}
          <br />
          <label>Last Status:</label> {lastStatus}
          <br />
          <Button size="sm" variant="light" onClick={handleHideMessage}>Hide Message</Button>
          <br />
          <label>Last Message: </label>{<JsonFormatter json={lastMessage} tabWidth='4' JsonStyle={{
            propertyStyle: { color: 'red' },
            stringStyle: { color: 'green' },
            numberStyle: { color: 'darkorange' }
          }} />}
        </div>
      );

    } else {

      return (<p style={{ "textAlign": "left", "marginLeft": "0px" }}>
        <Button size="sm" variant="light" onClick={handleFetchLastMessage}>Last Message</Button>
      </p>);
    }
  }

  return (
    <div className="last-message">
      {getLastMessage()}
    </div>
  );
}
export default LastMessage;
