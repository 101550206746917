import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import Spinner from '../../common/Spinner';

import { deviceOptions, gatewayNeededList } from './data';

const defaultForm = {
    deviceName: '',
    deviceNotes: '',
    fieldAccessNotes: '',
    gatewayNeeded: '',
};

const MarkerModal = (props) => {
    const { 
        calledSaveOrder,
        loadingSaveOrder,
        onCancel, 
        onHide, 
        onSave, 
        pendingMarker,
        show, 
    } = props;
    const [form, setForm] = useState(null);
    const [ deviceNameOther, setDeviceNameOther ] = useState('');
    const [ deviceNameNeeded, setDeviceNameNeeded ] = useState(true);
    const [ gatewayNeeded, setGatewayNeeded ] = useState(false);

    const handleSave = () => {
        let f = {...form};
        if ( f.deviceName === 'Other' ) {
            f.deviceName = deviceNameOther;
        }
        onSave(f);
        setForm(defaultForm);
        setDeviceNameOther('');
    }

    const handleCancel = () => {
        onCancel(form);
    }

    const handleSetForm = (event) => {
        setForm( {...form, [event.target.name]: event.target.value });
    }

    const isButtonDisabled = () => {
        let counter = 0;
        let count = 0;
        
        if ( pendingMarker && pendingMarker !== null && pendingMarker.fields ) {
            const { fields } = pendingMarker;

            for ( let i = 0; i < fields.length; i++ ) {
            }

            for ( let i = 0; i < fields.length; i++ ) {
                const field = fields[i];
                const name = field['name'];

                if ( field['required'] && field['required'] === true ) {
                    count++
                }
                
                if ( field['required'] && form[name] && form[name] !== '' ) {
                    counter++;
                }
            }
        }
        return ( (counter !== count) ||  (loadingSaveOrder && calledSaveOrder) );
    }

    const handleOnHide = () => {
        setForm(defaultForm);
        onHide();
    }

    const setColWidth = () => {
        const noDeviceName = ( !deviceNameNeeded || deviceNameNeeded === null);
        const noGatewayNeeded = ( !gatewayNeeded || gatewayNeeded === null);

        return (noDeviceName || noGatewayNeeded) ? 12 : 6;
    }

    useEffect(() => {
        setForm(defaultForm);

        return () => {
            setForm(defaultForm);
        }
    }, []);

    useEffect(() => {
        if ( pendingMarker && pendingMarker !== null && pendingMarker.hasOwnProperty('deviceNameNeeded') ) {
            setDeviceNameNeeded(pendingMarker.deviceNameNeeded);
        }

        if ( pendingMarker && pendingMarker !== null && pendingMarker.hasOwnProperty('gatewayNeeded') ) {
            setGatewayNeeded(pendingMarker.gatewayNeeded);
        }
    }, [pendingMarker]);

    return(
        <Modal
            show={show}
            onHide={handleOnHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Row>
                    <Col sm={12} md={setColWidth()}>
                        {deviceNameNeeded &&
                            <Form.Group>
                                <Form.Label>Device Name</Form.Label>
                                {deviceOptions.deviceNames.map((device, index) => 
                                    <Form.Check
                                        key={index}
                                        type="radio"
                                        label={device}
                                        name="deviceName"
                                        id={device}
                                        value={device}
                                        onChange={handleSetForm}
                                    />
                                )}
                                {(form !== null && deviceNameNeeded && form.deviceName && form.deviceName === 'Other') &&
                                    <Form.Control
                                        type="text"
                                        style={{width: "100%"}}
                                        name="deviceNameOther"
                                        value={deviceNameOther}
                                        onChange={(e) => setDeviceNameOther(e.target.value)}
                                    />
                                }
                            </Form.Group>
                        }
                    </Col>
                    <Col sm={12} md={setColWidth()}>
                        {gatewayNeeded &&
                            <Form.Group>
                                <Form.Label>Gateway Needed</Form.Label>
                                {gatewayNeededList.map((g, index) => 
                                    <Form.Check
                                        key={index}
                                        type="radio"
                                        label={g}
                                        name="gatewayName"
                                        id={g}
                                        value={g}
                                        onChange={handleSetForm}
                                    />
                                )}
                            </Form.Group>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Operation Notes</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3}
                                name="deviceNotes"
                                onChange={handleSetForm}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Field Access Notes</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3}
                                name="fieldAccessNotes"
                                onChange={handleSetForm}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleCancel}>Cancel</Button>
                {(calledSaveOrder && loadingSaveOrder) ? (
                    <Button 
                        variant="primary" 
                        onClick={handleSave}
                        disabled={isButtonDisabled()}
                    >
                        <Spinner color="light">...Saving</Spinner>
                    </Button>

                ) : (
                    <Button 
                        variant="primary" 
                        onClick={handleSave}
                        disabled={isButtonDisabled()}
                    >Save</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default MarkerModal;